import { Home as HomeIcon, TextSnippet } from '@mui/icons-material';
import { deepPurple, purple } from '@mui/material/colors';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LayoutProvider, LeftDrawerItem } from 'wcz-layout';
import packageJson from '../package.json';
import FruShipmentReport from './pages/FruShipmentReport';
import WyhqReport from './pages/WyhqReport';
import Home from './pages/Home';


export default function App() {
    const getLeftDrawerItems: LeftDrawerItem[] = [
        { title: "Home", icon: <HomeIcon fontSize="large" />, path: "/"},
        { title: "Report", icon: <TextSnippet fontSize="large" />, path: "/report" },
        { title: "WYHQ Report", icon: <TextSnippet fontSize="large" />, path: "/wyhqreport" },
    ];

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={deepPurple[300]} secondaryColor={purple[500]} leftDrawerItems={getLeftDrawerItems}>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/report' element={<FruShipmentReport />} />
                    <Route path='/report/:status' element={<FruShipmentReport />} />
                    <Route path='/wyhqreport' element={<WyhqReport />} />
                </Routes>
            </LayoutProvider>
        </BrowserRouter>
    );
}