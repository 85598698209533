import { Box, LinearProgress } from "@mui/material";
import { DataGridPro, GridCellEditStopParams, GridColDef, GridFilterItem, GridOverlay } from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchGet, fetchPut, LayoutContext, newGuid } from "wcz-layout";
import { TableContainer, GridToolbar, GridToolbarProps } from 'wcz-x-data-grid';
import FruShipment from "../models/FruShipment";
import { apiUrl } from "../utils/BaseUrl";
import moment from 'moment';

export default function FruShipmentReport() {
    const layout = useContext(LayoutContext); 
    const [isLoading, setIsLoading] = useState(false);
    //const [ initialTableState, setInitalTableState ] = useState<GridInitialStatePro>();
    const [searchText, setSearchText] = useState('');
    const [originalRows, setOriginalRows] = useState([] as FruShipment[]);
    const [rows, setRows] = useState<FruShipment[]>([] as FruShipment[]);
    const { status } = useParams();    

    useEffect(() => {
        layout.changeTitle("Monica FRU Shipment");
        setIsLoading(true);
        // eslint-disable-next-line
    }, []);

    useQuery<FruShipment[]>(["fruShipments"], ({ signal }) => fetchGet(`${apiUrl}/fru-ship/v1/report`, signal), {
        onSuccess: data => {
            const transformedData: FruShipment[] = data.map(d => ({ ...d, id: newGuid() }));
            setRows(transformedData);
            setOriginalRows(transformedData);
            setIsLoading(false);
        }
    });
    
    const columns: GridColDef[] = useMemo(() => [
        { field: 'confirmDate', headerName: "MSFT PO Received Date", width: 200, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'msftPo', headerName: "MSFT PO#", width: 150 },
        { field: 'msftPoLine', headerName: "Line Item", width: 120 },
        { field: 'f900PoInSo', headerName: "F900 PO in open SO report", width: 210 },
        { field: 'f900So', headerName: "F900 SO", width: 150 },
        { field: 'f900SoLine', headerName: "F900 SO Line", width: 150 },
        { field: 'f900Material', headerName: "F900 SO PN", width: 150 },
        { field: 'f900OrderQuantity', headerName: "F900 SO Qty", width: 120 },
        { field: 'f900SoCreateDate', headerName: "F900 SO Created Date", width: 180, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'f900SoDc', headerName: "F900 SO DC", width: 120 },
        { field: 'wyhqNote', headerName: "F900 Note", width: 300 },
        { field: 'wyDn', headerName: "F900 DN", width: 250 },
        { field: 'country', headerName: "Country", width: 100 },
        { field: 'itemDeliveryDate', headerName: "Spare parts On Dock Deadline", width: 250, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'f900PoReleaseDate', headerName: "F900 PO Release Date", width: 200, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'f900Po', headerName: "F900 PO", width: 120 },
        { field: 'f900PoItem', headerName: "F900 PO line item", width: 150 },
        { field: 'partNumber', headerName: "PN", width: 150 },
        { field: 'description', headerName: "Description", width: 450 },
        { field: 'unitPrice', headerName: "Unit Price", width: 150 },
        { field: 'msfNumber', headerName: "MSF#", width: 150 },
        { field: 'quantity', headerName: "Qty", width: 80 },
        { field: 'f6h1SoDate', headerName: "F6H1 SO Date", width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'f6h1SoShipDate', headerName: "F6H1 SO planning shipping date", width: 250, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'so', headerName: "F6H1 SO", width: 150 },
        { field: 'f6h1MaterialPn', headerName: "F6H1 Material PN", width: 150 },
        { field: 'dc', headerName: "F6H1 SO DC", width: 120 },
        { field: 'dn', headerName: "F6H1 DN", width: 120 },
        { field: 'createDate', headerName: "F6H1 DN Release date", width: 180, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'f6h1DnPackingCompleted', headerName: "F6H1 DN Packing Completed", width: 230, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'exportNumber', headerName: "F6H1 Export number", width: 170 },
        { field: 'hawb', headerName: "HAWB", width: 150 },
        { field: 'orderSource', headerName: "MMF Order", width: 150 },
        { field: 'forwarder', headerName: "Forwarder", width: 150 },
        { field: 'shippingDate', headerName: "Shipping Date", width: 140, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'etaDate', headerName: "ETA Date", width: 130, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'podDate', headerName: "MSFT POD Date", width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'logisticRemark', headerName: "Logistic Remark", width: 500 },
        { field: 'note', headerName: "F601 Note", width: 550, editable: true },
        { field: 'status', headerName: "Status", width: 250 },
        { field: 'mcioSubDate', headerName: "MCIO Submission Date", width: 200, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'mcioApprDate', headerName: "MCIO Approved Date", width: 180, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate() },
        { field: 'coo', headerName: "COO", width: 100 },
        { field: 'hsCode', headerName: "HS Code", width: 120 },
        // eslint-disable-next-line
    ] as GridColDef[], []);

    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <Box style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </Box>
            </GridOverlay>
        );
    }

    const search = (searchValue: string) => {
        setSearchText(searchValue);
        const filteredRows = originalRows.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return row[field]?.toString().toLowerCase().includes(searchValue.toLowerCase());
            });
        });
        setRows(filteredRows);
    }

    const getGridFilterItems = (): GridFilterItem[] => {
        if (status === "No status") {
            return [{ field: "status", operator: "isEmpty" }] as GridFilterItem[];
        }

        if (status) {
            return [{ field: "status", operator: "equals", value: status }] as GridFilterItem[];
        }

        return [] as GridFilterItem[];
    }

    const { mutate: updateNote } = useMutation((request: any) => fetchPut(`${apiUrl}/fru-ship/v1/report`, request))

    const updateCell = (params: GridCellEditStopParams) => {
        if (params.field === "note") {
            const foundFruShipments = rows.find(shipment => shipment.id === params.id);
            if (foundFruShipments) {
                const request = {
                    msftPo: foundFruShipments.msftPo,
                    msftPoLine: foundFruShipments.msftPoLine,
                    so: foundFruShipments.so,
                    updateNoteAllPoItems: false,
                    note: params.value as string,
                    wyhqNote: foundFruShipments.wyhqNote
                };

                updateNote(request);
            }
        }
        
    }

    return (
        <Box
            sx={{
                width: '100%', margin: '0px auto',
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: '600',
                },
                '& .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders': {
                    //backgroundColor: purple[300],
                    //color: 'white'
                },
            }}
        >            
            <TableContainer>
                <DataGridPro rows={rows} loading={isLoading} density="compact" sx={{ padding: '20px' }} columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'msftPo', sort: 'asc' }, { field: 'msftPoLine', sort: 'asc' }],
                        },
                        pinnedColumns: {
                            left: ['confirmDate', 'msftPo', 'msftPoLine']
                        },
                        filter: {
                            filterModel: { items: getGridFilterItems() }
                        }
                    }}
                    slots={{ toolbar: GridToolbar, loadingOverlay: CustomLoadingOverlay, }}
                    slotProps={{
                        toolbar: {
                            search: { value: searchText, setValue: (event: { target: { value: string; }; }) => search(event.target.value), clear: () => search(''), },
                            hideAddRecord: true,
                            export: true
                        } as GridToolbarProps,
                    }}
                    onCellEditStop={updateCell}                    
                />
            </TableContainer>
        </Box>
    );
}