import { Box, LinearProgress } from "@mui/material";
import { DataGridPro, GridCellEditStopParams, GridColDef, GridOverlay } from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchGet, fetchPut, LayoutContext, newGuid } from "wcz-layout";
import { TableContainer, GridToolbar, GridToolbarProps } from 'wcz-x-data-grid';
import WyhqFruShipment from "../models/WyhqFruShipment";
import { apiUrl } from "../utils/BaseUrl";
import moment from 'moment';


export default function WyhqReport() {
    const layout = useContext(LayoutContext);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [originalRows, setOriginalRows] = useState([] as WyhqFruShipment[]);
    const [rows, setRows] = useState<WyhqFruShipment[]>([] as WyhqFruShipment[]);

    useEffect(() => {
        layout.changeTitle("Monica FRU Shipment - WYHQ Report");
        setIsLoading(true);

        // eslint-disable-next-line
    }, []);

    useQuery<WyhqFruShipment[]>(["wyhqFruShipments"], ({ signal }) => fetchGet(`${apiUrl}/fru-ship/v1/wyhqreport`, signal), {
        onSuccess: data => {
            const transformedData: WyhqFruShipment[] = data.map(d => ({ ...d, id: newGuid() }));
            setRows(transformedData);
            setOriginalRows(transformedData);
            setIsLoading(false);
        }
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: 'status', headerName: "Status", width: 180, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'confirmDate', headerName: "MSFT PO Received Date", width: 200, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate(), headerClassName: 'fru-ship-report-theme--header' },
        { field: 'msftPo', headerName: "Purchase Order", width: 180, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'msftPoLine', headerName: "Line Item", width: 120, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'partNumber', headerName: "Seller Part#", width: 150, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'msfNumber', headerName: "Buyer Part#", width: 150, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'description', headerName: "Part Description", width: 450, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'quantity', headerName: "Order Quantity", width: 130, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'orderNotes', headerName: "Order Notes", width: 550, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'dc', headerName: "DC", width: 120, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'region', headerName: "Cat.", width: 120, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'shipFrom', headerName: "Ship From", width: 120, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'country', headerName: "Country", width: 100, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'f900So', headerName: "F900 SO#", width: 150, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'f900PoInSo', headerName: "F900 PO", width: 210, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'dn', headerName: "WCZ DN#", width: 120, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'forwarder', headerName: "Forwarder", width: 150, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'hawb', headerName: "Ship Out Tracking No.", width: 180, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'shippingDate', headerName: "Ship Out Date", width: 140, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate(), headerClassName: 'fru-ship-report-theme--header' },
        { field: 'podDate', headerName: "MSFT POD Date", width: 150, type: 'date', valueGetter: ({ value }) => value && new Date(value), valueFormatter: ({ value }) => moment(value).formatDate(), headerClassName: 'fru-ship-report-theme--header' },
        { field: 'logisticRemark', headerName: "Logistic Remark", width: 500, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'note', headerName: "Notes", width: 550, headerClassName: 'fru-ship-report-theme--header' },
        { field: 'wyhqNote', headerName: "F900 Note", width: 550, headerClassName: 'fru-ship-report-theme--header' },        
        // eslint-disable-next-line
    ] as GridColDef[], []);

    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <Box style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </Box>
            </GridOverlay>
        );
    }

    const search = (searchValue: string) => {
        setSearchText(searchValue);
        const filteredRows = originalRows.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return row[field]?.toString().toLowerCase().includes(searchValue.toLowerCase());
            });
        });
        setRows(filteredRows);
    };

    const { mutate: updateNote } = useMutation((request: any) => fetchPut(`${apiUrl}/fru-ship/v1/wyhqreport`, request))

    const updateCell = (params: GridCellEditStopParams) => {
        if (params.field === "wyhqNote") {
            const foundFruShipments = rows.find(shipment => shipment.id === params.id);
            if (foundFruShipments) {
                const request = {
                    msftPo: foundFruShipments.msftPo,
                    msftPoLine: foundFruShipments.msftPoLine,
                    so: foundFruShipments.f900So,
                    wyhqNote: foundFruShipments.wyhqNote
                };

                updateNote(request);
            }
        }

    }

    return (
        <Box
            sx={{
                width: '100%', margin: '0px auto',
                '& .fru-ship-report-theme--header': {                    
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: '600',
                },
            }}
        >
            <TableContainer>
                <DataGridPro rows={rows} loading={isLoading} density="compact" sx={{ padding: '20px' }} columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'msftPo', sort: 'asc' }, { field: 'msftPoLine', sort: 'asc' }],
                        },
                        pinnedColumns: {
                            left: ['status', 'confirmDate', 'msftPo', 'msftPoLine']
                        }
                    }}
                    slots={{ toolbar: GridToolbar, loadingOverlay: CustomLoadingOverlay, }}
                    slotProps={{
                        toolbar: {
                            search: { value: searchText, setValue: (event: { target: { value: string; }; }) => search(event.target.value), clear: () => search(''), },
                            export: true
                        } as GridToolbarProps,
                    }}
                    onCellEditStop={updateCell}
                    //onStateChange={state => console.log(state)}
                />
            </TableContainer>
        </Box>
    );
}