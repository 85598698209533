import { AccessTime, Approval, Cancel, Error, Help, LocalShipping, Pending, RemoveCircle, Warehouse, ChangeCircle } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader } from '@mui/material';
import { blue, brown, green, indigo, orange, purple, red } from '@mui/material/colors';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { fetchGet, LayoutContext } from 'wcz-layout';
import { apiUrl } from '../utils/BaseUrl';

export default function Home() {
    const layout = useContext(LayoutContext);
    const navigate = useNavigate();

    const [inProgressShipments, setInProgressShipments] = useState(0);
    const [waitingForTruckShipments, setWaitingForTruckShipments] = useState(0);
    const [onTheWayShipments, setOnTheWayShipments] = useState(0);
    const [deliveredShipments, setDeliveredShipments] = useState(0);
    const [shortageShipments, setShortageShipments] = useState(0);
    const [noStatusShipments, setNoStatusShipments] = useState(0);
    const [missingPgiShipments, setMissingPgiShipments] = useState(0);
    const [cancelledShipments, setCancelledShipments] = useState(0);
    const [mcioApprovalShipments, setMcioApprovalShipments] = useState(0);
    const [poChangeShipments, setPoChangeShipments] = useState(0);

    useEffect(() => {
        layout.changeTitle("Monica FRU Shipment");
        // eslint-disable-next-line
    }, []);

    useQuery<any>(["fruShipmentStatistics"], ({ signal }) => fetchGet(`${apiUrl}/fru-ship/v1/home`, signal), {
        onSuccess: data => {
            let inProgressQty = data.statusCountList.filter((item: any) => item.status === "In progress")[0]?.count ?? 0;
            setInProgressShipments(inProgressQty);
            let waitingForTruckQty = data.statusCountList.filter((item: any) => item.status === "Waiting for truck")[0]?.count ?? 0;
            setWaitingForTruckShipments(waitingForTruckQty);
            let onTheWayQty = data.statusCountList.filter((item: any) => item.status === "On the way")[0]?.count ?? 0;
            setOnTheWayShipments(onTheWayQty);
            let missingPgiQty = data.statusCountList.filter((item: any) => item.status === "Missing PGI")[0]?.count ?? 0;
            setMissingPgiShipments(missingPgiQty);
            let deliveredQty = data.statusCountList.filter((item: any) => item.status === "Delivered")[0]?.count ?? 0;
            setDeliveredShipments(deliveredQty);
            let shortageQty = data.statusCountList.filter((item: any) => item.status === "Shortage")[0]?.count ?? 0;
            setShortageShipments(shortageQty);
            let noStatusQty = data.statusCountList.filter((item: any) => item.status === null)[0]?.count ?? 0;
            setNoStatusShipments(noStatusQty);
            let cancelledQty = data.statusCountList.filter((item: any) => item.status === "Cancelled")[0]?.count ?? 0;
            setCancelledShipments(cancelledQty);
            let mcioApprovalQty = data.statusCountList.filter((item: any) => item.status === "MCIO Approval")[0]?.count ?? 0;
            setMcioApprovalShipments(mcioApprovalQty);
            let poChangeQty = data.statusCountList.filter((item: any) => item.status === "PO Change")[0]?.count ?? 0;
            setPoChangeShipments(poChangeQty);
        }
    });

    return (
        <React.Fragment>
            <List
                component="nav"
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                aria-labelledby="fruShipments-list-subheader"
                subheader={
                    <ListSubheader component="div" id="fruShipments-list-subheader">
                        Shipments by status
                    </ListSubheader>
                }
            >
                <ListItemButton onClick={() => navigate("/report/In progress")}>
                    <ListItemIcon>
                        <Pending sx={{ color: purple[500] }}/>
                    </ListItemIcon>
                    <ListItemText primary="In progress" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{inProgressShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/Waiting for truck")}>
                    <ListItemIcon>
                        <AccessTime sx={{ color: orange[500] }}/>
                    </ListItemIcon>
                    <ListItemText primary="Waiting for truck" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{waitingForTruckShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/On the way")}>
                    <ListItemIcon>
                        <LocalShipping sx={{ color: blue[500] }}/>
                    </ListItemIcon>
                    <ListItemText primary="On the way" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{onTheWayShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/Missing PGI")}>
                    <ListItemIcon>
                        <Error sx={{ color: brown[500] }}/>
                    </ListItemIcon>
                    <ListItemText primary="Missing PGI" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{missingPgiShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/Delivered")}>
                    <ListItemIcon>
                        <Warehouse sx={{ color: green[500] }}/>
                    </ListItemIcon>
                    <ListItemText primary="Delivered" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{deliveredShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/Shortage")}>
                    <ListItemIcon>
                        <RemoveCircle sx={{ color: red[500] }}/>
                    </ListItemIcon>
                    <ListItemText primary="Shortage" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{shortageShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/No status")}>
                    <ListItemIcon>
                        <Help />
                    </ListItemIcon>
                    <ListItemText primary="No status" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{noStatusShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/Cancelled")}>
                    <ListItemIcon>
                        <Cancel sx={{ color: red[500] }}/>
                    </ListItemIcon>
                    <ListItemText primary="Cancelled" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{cancelledShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/MCIO Approval")}>
                    <ListItemIcon>
                        <Approval sx={{ color: orange[500] }}/>
                    </ListItemIcon>
                    <ListItemText primary="MCIO approval" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{mcioApprovalShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/report/PO Change")}>
                    <ListItemIcon>
                        <ChangeCircle sx={{ color: indigo[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="PO Change" />
                    <ListItemSecondaryAction>
                        <span style={{ fontWeight: 'bold' }}>{poChangeShipments}</span>
                    </ListItemSecondaryAction>
                </ListItemButton>
            </List>
        </React.Fragment>
    );
}